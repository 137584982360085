import React, { Component } from 'react';
import { Carousel, CarouselItem, CarouselControl } from 'reactstrap';

//Star Rating
import StarRatings from 'react-star-ratings';
import ProfilePic from '../../assets/images/Vibharambha/profileImage.jpg';
import Img1 from '../../assets/images/Vibharambha/ParentImages/img1.jpg';
import Img2 from '../../assets/images/Vibharambha/ParentImages/img2.jpg';
import Img3 from '../../assets/images/Vibharambha/ParentImages/img3.jpg';
import Img4 from '../../assets/images/Vibharambha/ParentImages/img4.png';
import Img5 from '../../assets/images/Vibharambha/ParentImages/img5.png';

class ReviewSlider4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
    };
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.reviews = [
      {
        id: 1,
        image: Img1,
        name: "Utkarsh's Mom",
        rating: 5,
        desc: 'Our son, Utkarsh started with you from the tender age of 2. We are very happy that we found your preschool and daycare for him. He never fussed about going to school, rather waited eagerly for his school time. We thank the teachers and staff for making his learning so enjoyable',
      },
      {
        id: 2,
        image: Img2,
        name: 'Shilpa ',
        rating: 5,
        desc: 'SaiVihaan was not even able to hold pencil unlike many children of his age. We approached VibhArambha playschool with deep concern. The personal attention, focus and dedication from your Centre made him not just hold the pencil, but also write within 1.5 months. Asper the school name VibhArambha, it was again Arambha for Sai’s learning.',
      },
      {
        id: 3,
        image: Img3,
        name: 'Gowtham’s Dad',
        rating: 5,
        desc: 'I appreciate how the school took regular feedback from parents to improve the quality of education and also to meet the specific need of each child. Due to pandemic, though the school started a little late, the teacher made sure that children picked up and understood every aspect of the concepts. We are very thankful to the founder, Veena P for her passion for imparting education.',
      },
      {
        id: 4,
        image: Img4,
        name: 'Namrata Inamdar',
        rating: 5,
        desc: "I am thrilled to share my heartfelt appreciation for the Vibharambha Play School, a place that has truly shaped my daughter's early years in the most remarkable way. From the moment she stepped foot into this nurturing environment, her joy and enthusiasm for learning blossomed, and her social connections flourished.",
      },
      {
        id: 5,
        image: Img5,
        name: 'Geeth anjali',
        rating: 5,
        desc: 'Our sincere appreciation for the dedicated and caring teachers at the school. My child has made significant progress academically and emotionally. Best place to start your child’s pre-school, highly recommended, as they also take personal interest in engaging with your child.',
      },
      {
        id: 6,
        image: ProfilePic,
        name: 'Saroj yadav',
        rating: 5,
        desc: 'We have seen so many positive changes in our daughter in little span of time. All the teachers and staff are so warm and always approachable giving personal attention to every student. We highly recommend Vibharambh pre school.',
      },
    ];
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === this.props.clients.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.props.clients.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    return (
      <React.Fragment>
        <Carousel
          activeIndex={this.state.activeIndex}
          next={this.next}
          previous={this.previous}
        >
          {this.reviews.map((client, key) => (
            <CarouselItem
              key={key + 1}
              onExiting={this.onExiting()}
              onExited={this.onExited()}
            >
              <div key={key} className="customer-testi text-center">
                <p className="text-light para-dark h6 fst-italic mb-2">
                  " {client.desc} "
                </p>
                <StarRatings
                  rating={client.rating}
                  starRatedColor="#F17425"
                  numberOfStars={5}
                  name="rating"
                  starDimension="15px"
                  className="list-unstyled mb-0 mt-3"
                  starSpacing="1px"
                />
                <h6 className="text-light title-dark mt-1">
                  {client.name}
                  <small className="text-muted ms-1">{client.post}</small>
                </h6>
                <img
                  src={client.image}
                  style={{ height: 65, width: 65 }}
                  className="img-fluid avatar avatar-small rounded-circle mx-auto shadow"
                  alt=""
                />
              </div>
            </CarouselItem>
          ))}
          <CarouselControl direction="prev" onClickHandler={this.previous}>
            <span className="carousel-control-prev-icon"></span>
            <span className="sr-only">Previous</span>
          </CarouselControl>
          <CarouselControl direction="next" onClickHandler={this.next}>
            <span className="carousel-control-next-icon"></span>
            <span className="sr-only">Next</span>
          </CarouselControl>
        </Carousel>
      </React.Fragment>
    );
  }
}

export default ReviewSlider4;
